<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center px-0">
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <div class="banner">
            <b-img class="image-banner" :src="sideImg" alt="Login V2" />
            <div class="logo-container">
              <b-img src="@/assets/images/logo/clinic360WhiteHorizontal.svg" alt="Logo" width="auto" />
            </div>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex flex-wrap align-items-center auth-bg px-2 p-lg-5">
        <b-col align-self="end" sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <Logo class="mb-2" width="100px" />
          </div>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert> -->

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                  <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                    name="login-email" placeholder="john@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="Password"
                      @keyup.enter="loginAccess" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-overlay :show="loading" opacity="1" variant="primary">
                <b-button variant="primary" block @click="loginAccess" :disabled="invalid">
                  Sign in
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
        <b-col align-self="end" sm="12" md="12" lg="12">
          <div class="w-100 text-center mb-2 mb-lg-0">
            <b-img src="@/assets/images/logo/hippa_logo.webp" alt="Login V2" width="150px" class="img-fluid mt-5" />
            <b-img src="@/assets/images/logo/logoInnova_horizontal_gray.svg" alt="Login V2" width="150px"
              class="img-fluid mt-5" />
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <b-modal centered size="lg" v-model="conditionFacility" hide-footer no-close-on-backdrop no-close-on-esc>
      <b-form-group label="Choose a clinic" label-for="clinic">
        <v-select v-model="facility" :options="optionsFacilities" label="name">
        </v-select>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Logo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BOverlay,
  /* BAlert, */ VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import axiosU from "@/services/auth";
import axiosC from "@/services/admin/config";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BOverlay,
    //  BAlert,
    Logo,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  watch: {
    facility(newVal) {
      if (newVal && !this.onlyFacility) {
        this.changeRoute();

      }
    },
  },
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: "",
      user: null,
      token: null,
      permissions: null,
      conditionFacility: false,
      facility: null,
      optionsFacilities: [],
      facilityIdBack: "",
      onlyFacility: false,

      downImg: require("@/assets/images/logo/logo360_vertical.svg"),

      // validation rules
      required,
      email,
      loading: false,
    };
  },
  created() {
    this.viewImage();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    viewImage() {
      axiosC.configActive().then(({ login_dir_img }) => {
        this.sideImg = login_dir_img;
      });
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loginAccess();
        }
      });
    },
    changeRoute() {
      const user = this.user;
      const token = this.token;
      const permissions = this.permissions;
      const permisosTotal = this.getPermissions(permissions);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          ...user,
          role: user.roles[0].name,
          ability:
            user.roles[0].id === 1
              ? [
                {
                  action: "manage",
                  subject: "all",
                },
              ]
              : permisosTotal,
        })
      );
      localStorage.setItem("accessToken", token);
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.$ability.update(userData.ability);
      useJwt.setRefreshToken(token);
      localStorage.setItem("facility", this.facilityIdBack );
      localStorage.setItem("Facility", btoa(JSON.stringify(this.facility)));
      this.$variableGlobal.facility = this.facility;
      this.$router
        .replace(getHomeRouteForLoggedInUser(userData.role))
        .then(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `${this.$t("Hello")}, ${userData.name || userData.lastname
                  }`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
              },
            },
            {
              position: "bottom-right",
            }
          );
        });
    },
    loginAccess() {
      this.loading = true;
      const decodedUser = {
        email: this.userEmail,
        password: btoa(this.password),
      };
      axiosU
        .loginUser(decodedUser)
        .then(({ user, type, facilities_id, permissions, token }) => {
          if (user != null && facilities_id) {
            this.facilityIdBack = facilities_id
            this.user = user;
            this.token = token;
            this.permissions = permissions;
            this.optionsFacilities = user.facilities;
            const facilitySelected = user.facilities.find(fac => fac.id == user.default_facility_id)
            this.facility = facilitySelected;
            localStorage.setItem(
              "setFacilityOptions",
              btoa(JSON.stringify(this.optionsFacilities))
            );
            if (
              user.facilities.length === 1 ||
              user.roles[0].id === 1 ||
              user.roles[0].id === 12
            ) {
              this.onlyFacility = true;
              this.$variableGlobal.isMedicalCenter = facilitySelected.insurance
            }
            this.changeRoute();
          } else if (type == "authentication") {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  icon: "UserIcon",
                  variant: "danger",
                  text: `The user does not have permissions`,
                },
              },
              {
                position: "bottom-right",
              }
            );
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  icon: "UserIcon",
                  variant: "danger",
                  text: "Please contact an administrator (default facility doesn't exist)",
                },
              },
              {
                position: "bottom-right",
              }
            );
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                icon: "UserIcon",
                variant: "danger",
                text: `Credentials are invalid`,
              },
            },
            {
              position: "bottom-right",
            }
          );
          // console.log(error);
          // this.$refs.loginForm.setErrors(error);
        });
    },
    getPermissions(permissions) {

      // Crea un nuevo array de objetos.
      const formattedPermissions = [];

      // Itera sobre los permisos.
      for (const permission of permissions) {
        // Obtén el módulo, el submodulo, el elemento y la acción del permiso.
        const rest = permission.split(".");

        const action = rest.pop();
        const subject = rest.join(".");

        // Crea un nuevo objeto de permiso.
        const formattedPermission = {
          subject,
          action,
        };

        // Agrega el permiso formateado al nuevo array.
        formattedPermissions.push(formattedPermission);
      }
      return formattedPermissions
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.responsive-img {
  width: 100%;
  max-width: 350px;
  height: auto;
}

.image-banner {
  height: 100vh;
}

.banner {
  position: relative;
  text-align: center;
}

.logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-container img {
  width: 100%;
  height: auto;
}
</style>
